<template>
  <div class="ma-n3">
    <v-dialog
      v-model="dialogRestoreVersion"
      max-width="370"
    >
      <v-card>
        <v-card-title class="text-h5">
          Deseja restaurar a notificação para a versão selecionada?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            text
            @click="dialogRestoreVersion = false"
          >
            Fechar
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="restoreNotification()"
          >
            Restaurar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="d-flex">
      <div v-if="!loadingError && notificationHistory.length">
        <v-toolbar
          flat
          dark
          color="deep-purple accent-2"
        >
          <v-btn
            icon
            exact
            :to="{
              name: 'Notification',
              params: { id: $route.params.notification_id },
            }"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-toolbar-title>Voltar</v-toolbar-title>
        </v-toolbar>
        
        <v-list  class="py-0 qj-notification-history">
          <div v-if="loading" class="d-flex justify-center">
            <v-progress-circular
            indeterminate
            color="deep-purple accent-2"
            :size="50"
            ></v-progress-circular>
          </div>
          <v-list-item-group mandatory color="primary" v-model="selectedNotification">
            <template v-for="(notification, index) in notificationHistory" >
              <v-list-item :key="notification.id" :title="notification.updated_by">
                <v-list-item-content>
                  <v-list-item-title>{{notification.updated_by | firstWord }}</v-list-item-title>
                  <v-list-item-subtitle>{{notification.created_at | moment('L LT')}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" 
                        v-on="on" 
                        @click="dialogRestoreVersion = true"
                      >
                        <v-icon>mdi-arrow-u-left-bottom</v-icon>
                      </v-btn>
                    </template>
                    <span>Restaurar versão</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="index"></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </div>

      <!-- Erro ao carregar a lista -->
      <div v-if="loadingError" class="pl-6" style="margin-left: calc(50vh); margin-top: 50px">
        <v-alert
          type="error"
          text
        >
          <v-row align="center">
            <v-col class="grow">
              Não foi possível carregar a lista de histórico das notificações.
            </v-col>
            <v-col class="shrink">
              <v-btn color="primary" @click="getNotificationHistory()">
                Tentar novamente
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </div>

      <div v-if="!loadingError && notificationHistory.length" class="flex-grow-1">
        <v-toolbar
          flat
          dark
          color="deep-purple accent-2"
        >
          <div class="d-flex flex-grow-1 justify-space-around">
            <div class="text-center" style="width: 50%;">
              <div v-if="notificationHistory[selectedNotification]">
                {{ notificationHistory[selectedNotification].updated_by | firstWord }},
                {{ notificationHistory[selectedNotification].created_at | moment('L LT') }}
              </div>
            </div>

            <div class="text-center font-weight-bold" style="width: 50%;">
              ATUAL
            </div>
          </div>

          <v-switch
            v-model="showOnlyDifferences"
            label="Apenas diferenças"
            hide-details
          ></v-switch>
        </v-toolbar>

        <div v-if="loading" class="d-flex justify-center">
          <v-progress-circular
            indeterminate
            color="deep-purple accent-2"
            :size="50"
          ></v-progress-circular>
        </div>

        <div class="d-flex qj-notification-history-diff" v-if="notificationHistory"
          :class="{ 'show-only-differences': showOnlyDifferences }">
          <NotificationDetail :notification="notificationHistory[selectedNotification]" :current-notification="notification" />
          <NotificationDetail :notification="notification" :current-notification="notificationHistory[selectedNotification]"  />
        </div>
      </div>
    </div>

    <div v-if="!loadingError && !notificationHistory.length" class="pa-4 text-center text--secondary">
      Nenhuma alteração encontrada.
    </div>
  </div>
</template>

<script>
import serviceNotification from "@/services/notification";
import { mapActions, mapGetters, mapMutations} from 'vuex';

export default{
  components: {
    NotificationDetail: () => import('./components/notificationDetail.vue'),
  },
  data: () => ({
    /** Indica se está carregando a notificação. */
    loadingNotification: false,

    /** Indica se houve um erro ao carregar a notificação. */
    loadingNotificationError: false,

    /** A notificação do histórico. */
    notification: null,

    /** Indica se devem ser exibidas apenas as diferenças. */
    showOnlyDifferences: true,

    selectedNotification: 0,
    dialogRestoreVersion: false,
  }),
  async created() {
    this.loadNotification();
    await this.getNotificationHistory();
  },
  computed:{
    ...mapGetters("notification_history", {
      notificationHistoryList: "list",
      loadingError: "loadingError",
      loading: "loading"
    }),
    notificationHistory() {
      return this.notificationHistoryList.objects.sort((a, b) => {
        if (new Date(a.updated_at) > new Date(b.updated_at)) return -1;
        if (new Date(a.updated_at) < new Date(b.updated_at)) return 1;
        return 0;
      });
    }
  },
  methods: {
    ...mapActions("creditor", {
      loadCreditor: "ActionsLoadCreditor",
    }),
    ...mapMutations("notification_history", {
      setSelectedNotificationHistory: "setSelected",
    }),
    ...mapActions("notification_history", {
      getAllNotificationHistory: "getAll"
    }),
    ...mapMutations("snackbar", {
      showSnackbar: "showSnackbar",
    }),
    ...mapActions("notification", {
      updateNotification: "update"
    }),

    /** Carrega a notificação. */
    loadNotification() {
      console.log("Carregando a notificação", this.$route.params.notification_id);

      this.loadingNotification = true;
      this.loadingNotificationError = false;

      serviceNotification
        .get(this.$route.params.notification_id)
        .then((response) => {
          console.log("Notificação carregada");
          this.notification = response.data;
        })
        .catch((error) => {
          console.error("Não foi possível carregar a notificação.", error);
          this.loadingNotificationError = true;
        })
        .finally(() => {
          this.loadingNotification = false;
        });
    },

    async getNotificationHistory() {
      let params = {
        notification_id: this.$route.params.notification_id,
        type_modification: "UPDATE",
        limit: 1000,
      }
      await this.getAllNotificationHistory(params);
    },
    restoreNotification(){
      let notification = this.notificationHistoryList.objects[this.selectedNotification]
      let payload = {
        days_type: notification.days_type,
        days: notification.days,
        email_asset_url: notification.email_asset_url,
        email_subject: notification.email_subject,
        type: notification.type,
        min_days_after_proposal_agreed: notification.min_days_after_proposal_agreed,
        min_days_until_due_date: notification.min_days_until_due_date,
        payment_method: notification.payment_method,
        payment_type: notification.payment_type,
        push_body: notification.push_body,
        push_title: notification.push_title,
        rule_id: notification.rule_id,
        send_next_business_day: notification.send_next_business_day,
        deal_tags: notification.deal_tags,        
        sms: notification.sms,
        whatsapp_initiative: notification.whatsapp_initiative,
        whatsapp_params: notification.whatsapp_params,
        when: notification.when,
        active: notification.active,
        flash_sms: notification.flash_sms,
        id: this.$route.params.notification_id,
      }
      this.updateNotification(payload)
      .then(()=> {
        this.getNotificationHistory()
        this.dialogRestoreVersion = false;
      })
      .catch(() => {
        this.dialogRestoreVersion = false;
        this.showSnackbar("Ops, ocorreu um erro!");
      })
    }
  },
}
</script>

<style large="scss">
.show-only-differences {
  .qj-notification-detail {
    display: none;
  }

  .different {
    display: block;
  }
}

.qj-notification-history {
  border-right: 1px solid #e0e0e0 !important;
  max-height: calc(100vh - 128px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  width: 220px;
}

.qj-notification-history-diff {
  border-left: 1px solid #e0e0e0;
  max-height: calc(100vh - 128px);
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
